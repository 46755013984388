@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Causten";
  src: url("./fonts/Causten-Regular.otf");
}

@font-face {
  font-family: "ZeroesTwo";
  src: url("./fonts/ZeroesTwo.ttf");
}

@font-face {
  font-family: "SpaceGrotesk";
  src: url("./fonts/SpaceGrotesk-Regular.ttf");
}

@font-face {
  font-family: "Inter";
  src: url("./fonts/Inter-Regular.ttf");
}

.myBtn {
  background: #c0a0f5;
  border: 5px solid #a270f5;
  border-radius: 50px;
  filter: drop-shadow(0px 10px 0px #651fda);
}

.myEllipse {
  background: linear-gradient(180deg, #ffffff 0%, #c0a0f5 99.99%, #a270f5 100%);
}

.mountainLG {
  background: url("../src/assets/footer-bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.mountainSM {
  background: url("../src/assets/footer-mobile-bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
